const withholdingTaxReceipt = {
    title: '원천징수 영수증',
    methods: [
        {
            title: '온라인(PC)',
            description: [
                {
                    id: 1,
                    content: '홈택스 홈페이지 접속 후 로그인',
                },
                {
                    id: 2,
                    content: "우측 바로가기 메뉴에서 'My홈택스' 클릭",
                },
                {
                    id: 3,
                    content: '연말정산∙지급명세서 > 지급 명세서 등 제출 내역 클릭',
                },
                {
                    id: 4,
                    content: "증명할 '지급 명세서 보기' 클릭 후 인쇄",
                },
            ],
        },
        {
            title: '오프라인',
            description: [
                {
                    id: 1,
                    content: '가까운 주민센터나 세무서, 무인발급기 방문',
                },
            ],
        },
    ],
}
const payStub = {
    title: '급여 명세서',
    methods: [
        {
            description: [
                {
                    id: 1,
                    content: '회사 담당자에게 급여 명세서 요청',
                },
            ],
        },
    ],
}

const proofOfIncomeAmount = {
    title: '소득금액 증명원',
    methods: [
        {
            title: '온라인(PC)',
            description: [
                {
                    id: 1,
                    content: '홈택스 홈페이지 접속 후 로그인',
                },
                {
                    id: 2,
                    content: '상단 민원증명 > 소득금액증명 메뉴 클릭',
                },
                {
                    id: 3,
                    content: "정보 작성 후 '신청하기' 클릭",
                },
            ],
        },
        {
            title: '오프라인',
            description: [
                {
                    id: 1,
                    content: '가까운 주민센터나 세무서, 무인발급기 방문',
                },
            ],
        },
    ],
}
const certificateOfEmployment = {
    title: '재직증명서',
    methods: [
        {
            description: [
                {
                    id: 1,
                    content: '회사 담당자에게 급여 명세서 요청',
                },
            ],
        },
    ],
}
const proofOfHealthInsuarance = {
    title: '건강보험 자격득실 확인서',
    methods: [
        {
            title: '온라인(PC)',
            description: [
                {
                    id: 1,
                    content: '국민건강보험 홈페이지 접속 후 로그인',
                },
                {
                    id: 2,
                    content: '방문자별 맞춤 메뉴 > 자격득실 확인서 발급 메뉴 클릭',
                },
                {
                    id: 3,
                    content: "'프린트 발급' 클릭 후 인쇄",
                },
            ],
        },
        {
            title: '오프라인',
            description: [
                {
                    id: 1,
                    content: '가까운 국민건강보험공단 지사나 무인발급기 방문',
                },
            ],
        },
    ],
}
const businessRegistration = {
    title: '사업자 등록증 사본(사업자의 경우)',
    methods: [
        {
            title: '온라인(PC)',
            description: [
                {
                    id: 1,
                    content: '국민건강보험 홈페이지 접속 후 로그인',
                },
                {
                    id: 2,
                    content: '상단 민원증명 > 사업자 등록증 재발급 메뉴 클릭',
                },
                {
                    id: 3,
                    content: "정보 작성 후 '신청하기' 클릭",
                },
            ],
        },
        {
            title: '오프라인',
            description: [
                {
                    id: 1,
                    content: '가까운 세무서 방문',
                },
            ],
        },
    ],
}

export const verifyMethod = {
    13: [],
    14: [withholdingTaxReceipt, payStub, proofOfIncomeAmount],
    15: [certificateOfEmployment, proofOfHealthInsuarance, businessRegistration],
}
