<template>
    <div class="p-0">
        <div class="page-modal" v-if="badge.id === 45">
            <div class="back-button-wrapper" @click="onClickClose">
                <!-- <i class="back-button material-icons">close</i> -->
                <img class="back-button" :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" />
                <span class="header-title">{{ this.options.verificationBadge.name }}</span>
            </div>

            <div class="main-title">{{ currentCollegesContents.mainTitle }}</div>
            <section class="section">
                <div v-if="currentCollegesContents.itemDesc1">
                    <div class="item-title m-b-11">유의사항</div>
                    <div
                        v-for="(desc, index) in currentCollegesContents.itemDesc1"
                        :key="index"
                        class="item-desc1 m-t-8"
                        v-html="desc"
                    ></div>
                </div>
                <div v-if="currentCollegesContents.itemDesc2">
                    <div class="item-title">서류 발급 방법</div>
                    <div
                        v-for="(desc, index) in currentCollegesContents.itemDesc2"
                        :key="index"
                        class="item-desc2 m-t-5"
                        v-html="desc"
                    ></div>
                </div>
            </section>
            <AddPhoto :badge="badge" :imgSrc="currentCollegesContents.imgSrc" @input="onInputPhoto" />
            <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
        </div>

        <div class="page-modal" v-if="badge.id === 14">
            <div class="back-button-wrapper" @click="onClickClose">
                <img class="back-button" :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" />
                <span class="header-title">{{ this.options.verificationBadge.name }}</span>
            </div>

            <div class="main-title">{{ currentIncomesContents.mainTitle }}</div>
            <section class="section">
                <div v-if="currentIncomesContents.itemDesc1">
                    <div class="item-title m-b-11">유의사항</div>
                    <div
                        v-for="(desc, index) in currentIncomesContents.itemDesc1"
                        :key="index"
                        class="item-desc1 m-t-8"
                        v-html="desc"
                    ></div>
                </div>
                <div v-if="currentIncomesContents.itemDesc3">
                    <div class="item-title">서류 발급 방법</div>
                    <span class="item-subtitle">온라인(PC)</span>
                    <div
                        v-for="(desc, index) in currentIncomesContents.itemDesc3"
                        :key="index"
                        class="item-desc2 m-b-2"
                        v-html="desc"
                    ></div>
                </div>

                <div v-if="currentIncomesContents.itemDesc6">
                    <span class="item-subtitle">온라인(스마트폰)</span>
                    <div
                        v-for="(desc, index) in currentIncomesContents.itemDesc6"
                        :key="index"
                        class="item-desc2 m-b-2"
                        v-html="desc"
                    ></div>
                </div>

                <div v-if="currentIncomesContents.itemDesc4">
                    <span class="item-subtitle">오프라인</span>
                    <div
                        v-for="(desc, index) in currentIncomesContents.itemDesc4"
                        :key="index"
                        class="item-desc2 m-t-5"
                        v-html="desc"
                    ></div>
                </div>
            </section>
            <AddPhoto :badge="badge" :imgSrc="currentIncomesContents.imgSrc" @input="onInputPhoto" />
            <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
        </div>

        <div class="page-modal" v-if="badge.id === 15">
            <div class="back-button-wrapper" @click="onClickClose">
                <!-- <i class="back-button material-icons">close</i> -->
                <img class="back-button" :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" />
                <span class="header-title">직장(직업) 인증</span>
            </div>

            <div v-if="selected === 'BUSINESS_CARD'">
                <section class="section">
                    <div v-if="currentCompaniesContents.itemDesc1">
                        <div class="item-title">유의사항</div>
                        <div
                            v-for="(desc, index) in currentCompaniesContents.itemDesc1"
                            :key="index"
                            class="item-desc1 m-t-5"
                            v-html="desc"
                        ></div>
                    </div>
                    <div v-if="currentCompaniesContents.itemDesc3">
                        <div class="item-title">서류 발급 방법</div>
                        <span class="item-subtitle">온라인(PC)</span>
                        <div
                            v-for="(desc, index) in currentCompaniesContents.itemDesc3"
                            :key="index"
                            class="item-desc2 m-b-2"
                            v-html="desc"
                        ></div>
                        <span class="item-subtitle">오프라인</span>
                        <div
                            v-for="(desc, index) in currentCompaniesContents.itemDesc4"
                            :key="index"
                            class="item-desc2 m-t-5"
                            v-html="desc"
                        ></div>
                    </div>
                    <div v-if="currentCompaniesContents.itemDesc5">
                        <div class="item-title">인증 방법</div>
                        <div
                            v-for="(desc, index) in currentCompaniesContents.itemDesc5"
                            :key="index"
                            class="item-desc1 m-t-15 m-b-10"
                            v-html="desc"
                        ></div>
                    </div>
                </section>
                <AddPhoto :badge="badge" :imgSrc="currentCompaniesContents.imgSrc" @input="onInputPhoto" />
                <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
            </div>
        </div>

        <div class="page-modal" v-if="badge.id === 13">
            <div v-if="page === 1" :key="page">
                <div class="back-button-wrapper" @click="onClickClose">
                    <!-- <i class="back-button material-icons">close</i> -->
                    <img
                        class="back-button"
                        :src="require(`@/assets/images/icons/close.png`)"
                        width="24px"
                        height="24px"
                    />
                    <span class="header-title">{{ currentSelfContents.mainTitle }} </span>
                </div>
                <section class="section">
                    <div v-if="currentSelfContents.itemDesc1">
                        <div class="item-title">인증방법</div>
                        <div
                            v-for="(desc, index) in currentSelfContents.itemDesc1"
                            :key="index"
                            class="item-desc1 m-t-15"
                            v-html="desc"
                        ></div>
                    </div>
                </section>
                <AddPhoto :badge="badge" :imgSrc="currentSelfContents.imgSrc" @input="onInputPhoto" />
                <BottomButton
                    :label="options.menuId === 2 ? '다음' : '제출하기'"
                    @click="onClickSubmit"
                    :disabled="disabled"
                />
            </div>

            <div class="page-modal" v-else :key="page">
                <div class="back-button-wrapper" @click="onClickClose">
                    <img
                        class="back-button"
                        :src="require(`@/assets/images/icons/close.png`)"
                        width="24px"
                        height="24px"
                    />
                    <span class="header-title">학력 서류 대리발급 신청 </span>
                </div>
                <section class="section m-t-30">
                    <h1 class="title f-22 m-l-20">주소 입력</h1>
                    <div class="description f-13 m-l-20 m-r-20 m-t-10">
                        현재 <span class="spoqa-f-bold" style="color: red">전입신고 되어있는 주소</span>를 정확히
                        적어주세요<br /><br />
                        <span class="m-l-5"
                            >*신분증 주소와 전입신고된 주소가 다를 수 있습니다 (이사를 한 후에 신분증을 발급받았거나,
                            새로운 주소로 전입 신고를 하지 않은 경우 신분증 주소와 전입신고 주소는 일치합니다.)</span
                        >
                    </div>
                    <div class="adress-content m-t-12 m-b-0 m-l-20 m-r-20">
                        <TextareaWithX
                            @input="onInputComment"
                            :placeholder="placeholder"
                            v-model="inputContent"
                            :ref="'comment'"
                            :setClass="'content-area m-0'"
                        />
                    </div>
                </section>
                <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
            </div>
        </div>
    </div>
</template>

<script>
import verificationBadgeService from '@/services/verification-badge'
import { verifyMethod } from '@/assets/constants/cert-center'
import AddPhoto from '../../routes/my-page/components/AddPhoto'

export default {
    name: 'ModalCertificate',
    props: {
        options: {
            type: Object,
            required: true,
        },
        menuId: {
            type: Number,
            required: false,
        },
    },
    components: {
        AddPhoto,
    },
    data: () => ({
        profileItems: [],
        showSearch: true,
        searchValue: '',
        numberValue: 0,
        photos: [],
        selectedTab: 'BUSINESS_CARD',
        tabs: ['BUSINESS_CARD', 'DOCUMENT'],
        page: 1,
    }),
    mounted() {},
    computed: {
        selected() {
            return this.selectedTab
        },
        disabled() {
            return this.photos.length === 0
        },
        badge() {
            return this.options.verificationBadge
        },
        verificationMethod() {
            return this.badge.verification_method.split('|')
        },
        verificationMethodDetail() {
            return verifyMethod[this.badge.id]
        },
        isReSubmit() {
            const declined = this.$store.getters.myVerificationBadges.filter(
                badge => !badge.enabled && badge.rejection_reason
            )

            return declined.some(badge => badge.verification_badge_id === this.badge.id)
        },
        placeholder() {
            return '현재 신고되어있는 주소를 입력해주세요'
        },
        selfContents() {
            return {
                1: {
                    mainTitle: '신원 인증',
                    itemDesc1: [`주민등록번호 뒤 6자리가 보이지 않도록 지운 신분증 사진을 올려주세요.`],
                    itemDesc2: [
                        `1. 학교 포털 사이트에서 발급`,
                        `2. 정부 24에서 발급<a href="your-url-here" style="color: blue; text-decoration: underline;">(발급방법 보기)</a>`,
                    ],
                },
                2: {
                    mainTitle: '학력 서류 대리발급 신청',
                    itemDesc1: [
                        `1. 대리 발급에는 <span style="color:red;">주민등록증</span> 혹은 <span style="color:red;">운전면허증만</span> 사용 가능합니다. (여권 불가)`,
                        `<span style="color:red;">주민번호 뒷번호와 주소가 모두 보이는 (마스킹 하지 않은) 신분증 사진</span>을 올려주셔야 합니다.`,
                    ],
                    imgSrc: require('@/assets/images/certi-center/verification.png'),
                },
            }
        },
        collegesContents() {
            return {
                1: {
                    mainTitle: '졸업증명서 / 수료증',
                    itemDesc1: [`석사, 박사의 경우 각 학력 인증 서류를 순서대로 올려주세요. (학사-석사-박사 순)`],
                    itemDesc2: [
                        `1. 학교 포털 사이트에서 발급`,
                        `2. 정부 24에서 발급<a href="https://blog.naver.com/jeju8/223211786525" style="color: blue; target='_blank'">(발급방법 보기)</a>`,
                    ],
                },
                2: {
                    mainTitle: '학생증',
                    itemDesc1: [
                        `1. 학교명과 성명이 동시에 확인 가능한 학생증 사진을 올려주세요. (학교명이 뒷면에 있는 경우 앞면과 뒷면을 각각 찍어 모두 올려주세요.)`,
                        `2. 신용 카드 기능이 있는 경우 카드 번호는 가리고 올려주세요.`,
                        `3. 석사, 박사의 경우 각 학력 인증 서류를 순서대로 올려주세요. (학사-석사-박사 순)`,
                    ],
                    imgSrc: require('@/assets/images/certi-center/student.png'),
                },
                3: {
                    mainTitle: '학교 포털 캡쳐',
                    itemDesc1: [
                        `1. 본인의 이름, 학교명, 학적 상태(졸업 여부)가 동시에 보이도록 캡쳐해주세요.<br /> (세 가지 정보가 한 사진에 보이지 않는 경우 캡쳐 가능한 2장 이상의 사진으로 나누어서 업로드 해주세요.)`,
                        `2. 석사, 박사의 경우 각 학력 인증 서류를 순서대로 올려주세요. (학사-석사-박사 순)`,
                    ],
                    imgSrc: require('@/assets/images/certi-center/school.png'),
                },
            }
        },
        incomesContents() {
            return {
                1: {
                    mainTitle: '원천징수영수증',
                    itemDesc1: [
                        `<span style="color: red;">1. 최근 1년 이내 발급분만 인정됩니다.</span>`,
                        `2. 도용 및 위조는 중범죄이며 형사처벌 될 수 있습니다.`,
                    ],
                    itemDesc3: [
                        `국세청 홈택스에서 발급<a href="https://m.blog.naver.com/swifty_7/223427356124" style="color: blue; target='_blank'">(발급방법 보기)</a>`,
                    ],
                    itemDesc6: [
                        `국세청 손택스에서 발급<a href="https://m.blog.naver.com/swifty_7/223427356124" style="color: blue; target='_blank'">(발급방법 보기)</a>`,
                    ],
                    itemDesc4: [`가까운 주민센터, 구청 방문 발급`],
                    imgSrc: require('@/assets/images/certi-center/taxReceipt.png'),
                },
                2: {
                    mainTitle: '소득금액증명원',
                    itemDesc1: [
                        `<span style="color: red;">1. 최근 1년 이내 발급분만 인정됩니다.</span>`,
                        `2. 도용 및 위조는 중범죄이며 형사처벌 될 수 있습니다.`,
                    ],
                    itemDesc3: [
                        `국세청 홈택스에서 발급<a href="https://help.jobis.co/hc/ko/articles/115004467727-%EC%86%8C%EB%93%9D%EA%B8%88%EC%95%A1%EC%A6%9D%EB%AA%85-%EB%B0%9C%EA%B8%89%EB%B0%A9%EB%B2%95" style="color: blue; target='_blank'">(발급방법 보기)</a>`,
                    ],
                    itemDesc6: [
                        `국세청 손택스에서 발급<a href="https://blog.naver.com/PostView.nhn?blogId=ntscafe&logNo=222278246357&categoryNo=29&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=1&from=postView" style="color: blue; target='_blank'">(발급방법 보기)</a>`,
                    ],
                    itemDesc4: [`가까운 주민센터, 구청 방문 발급`],
                    imgSrc: require('@/assets/images/certi-center/incomeAmount.png'),
                },
                3: {
                    mainTitle: '지급명세서',
                    itemDesc1: [
                        `<span style="color: red;">1. 최근 1년 이내 발급분만 인정됩니다.</span>`,
                        `2. 도용 및 위조는 중범죄이며 형사처벌 될 수 있습니다.`,
                    ],
                    itemDesc3: [
                        `국세청 홈택스에서 발급<a href="https://help.jobis.co/hc/ko/articles/360012342554-%EC%A7%80%EA%B8%89%EB%AA%85%EC%84%B8%EC%84%9C-%EA%B7%BC%EB%A1%9C-%EC%9D%B4%EC%9E%90-%EC%82%AC%EC%97%85-%ED%87%B4%EC%A7%81-%EB%93%B1-%EA%B7%BC%EB%A1%9C%EC%86%8C%EB%93%9D%EC%9B%90%EC%B2%9C%EC%A7%95%EC%88%98%EC%98%81%EC%88%98%EC%A6%9D-%EB%B0%9C%EA%B8%89%EB%B0%A9%EB%B2%95" style="color: blue; target='_blank'">(발급방법 보기)</a>`,
                    ],
                    itemDesc4: [`가까운 주민센터, 구청 방문 발급`],
                    imgSrc: require('@/assets/images/certi-center/statementEarnings.png'),
                },
            }
        },
        companiesContents() {
            return {
                1: {
                    mainTitle: '명함/사원증',
                    itemDesc1: [
                        `1. 회사명과 성명을 동시에 확인 가능한 명함(혹은 사원증) 사진을 올려주세요.`,
                        `2. 한쪽 면에 회사명과 성함이 함께 나오지 않은 경우 뒷면까지 찍어서 올려주세요.`,
                    ],
                    imgSrc: require('@/assets/images/certi-center/businessCard.png'),
                },
                2: {
                    mainTitle: '재직증명서',
                    itemDesc1: [`최근 6개월 이내 발급분을 올려주세요.`],
                },
                3: {
                    mainTitle: '사업자등록증',
                    itemDesc1: [`상호/법인명과 대표자 이름을 함께 확인 할 수 있는 사업자등록증을 올려주세요.`],
                    itemDesc3: [
                        `1.국민건강보험 홈페이지 접속 후 로그인`,
                        `2.상단 민원증명 > 사업자 등록증 재발급 메뉴 클릭`,
                        `3.정보 작성 후 '신청하기' 클릭`,
                    ],
                    itemDesc4: [`가까운 세무서 방문`],
                    imgSrc: require('@/assets/images/certi-center/businessowner.png'),
                },
                4: {
                    mainTitle: '자격증/면허증',
                    itemDesc5: [`교원 자격증, 의사 면허증 등 공인기관에서 발급한 자격증과 면허증을 올려주세요.`],
                    imgSrc: require('@/assets/images/certi-center/certificate.png'),
                },
                5: {
                    mainTitle: '건강보험자격득실확인서 ',
                    itemDesc1: [`최근 6개월 이내 발급분을 올려주세요`],
                    itemDesc3: [
                        `1. 국민건강보험 홈페이지 접속 후 로그인`,
                        `2. 방문자별 맞춤 메뉴 > 자격득실 확인서 발급 메뉴 클릭`,
                        `3. '프린트 발급' 클릭 후 인쇄`,
                    ],
                    itemDesc4: [`가까운 국민건강보험공단 지사나 무인발급기 방문`],
                    imgSrc: require('@/assets/images/certi-center/insurance.png'),
                },
            }
        },
        currentSelfContents() {
            return this.selfContents[this.options.menuId] || {}
        },
        currentCollegesContents() {
            return this.collegesContents[this.options.menuId] || {}
        },
        currentIncomesContents() {
            return this.incomesContents[this.options.menuId] || {}
        },
        currentCompaniesContents() {
            return this.companiesContents[this.options.menuId] || {}
        },
    },
    methods: {
        isSelected(tab) {
            return this.selected === tab
        },
        onClickTab(tab) {
            this.selectedTab = tab
        },
        async onClickSubmit() {
            if (this.badge.id === 13 && this.options.menuId === 2) {
                if (this.page === 1) {
                    this.page++
                } else if (this.page === 2) {
                    if (this.checkWordCount()) {
                        return
                    }
                    try {
                        await this.$modal
                            .basic({
                                title: '현재 신고된 주소지가 맞나요?',
                                body: `전입신고 이후에는 신분증 주소와 신고된 주소지가 다를 수 있습니다`,
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: 'CONFIRM',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(async idx => {
                                if (idx === 0) {
                                } else {
                                    this.$loading(true)
                                    const payload = this.preparedPayloadReq()
                                    await verificationBadgeService.requestBadge(payload)
                                    await this.$store.dispatch('loadMyVerificationBadges')
                                    this.$loading(false)

                                    await this.$modal
                                        .basic({
                                            body: `대리 서류 발급 신청이 완료되었습니다. 직접 오프라인 주민센터 방문 후 처리되는 절차인지라 1~3 영업일 정도 소요되는 점 참고 부탁드립니다.`,
                                            buttons: [
                                                {
                                                    label: 'CONFIRM',
                                                    class: 'btn-primary',
                                                },
                                            ],
                                        })
                                        .then(async () => {
                                            this.$emit('close')
                                            this.$stackRouter.pop()
                                        })
                                    this.$loading(false)
                                }
                            })
                    } catch (e) {
                        this.$toast.error('에러가 발생했습니다. 다시 시도해주세요')
                        console.log(e)
                    } finally {
                    }
                }
            } else {
                const payload = this.preparedPayload()
                try {
                    this.$loading(true)

                    await verificationBadgeService.create(this.$store.getters.me.id, payload)
                    await this.$store.dispatch('loadMyVerificationBadges')

                    this.$modal
                        .basic({
                            body: `서류가 제출되었어요`,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(() => {
                            this.$emit('close')

                            if (this.badge.id === 15) {
                                this.$stackRouter.pop()
                            } else if (this.badge.id === 45 || this.badge.id === 14) {
                                this.$stackRouter.pop()
                                this.$stackRouter.pop()
                            }
                        })
                    this.$loading(false)
                } catch (e) {
                    this.$toast.error('에러가 발생했습니다. 다시 시도해주세요')
                    console.log(e)
                }
            }
        },
        onClickClose() {
            this.$emit('close')
        },
        preparedPayload() {
            try {
                const payload = new FormData()

                payload.append('user_id', this.$store.getters.me.id)
                payload.append('verification_type', 'photo')
                payload.append('verification_badge_id', this.badge.id)

                this.photos.forEach((photo, idx) => {
                    if (photo.blob) {
                        payload.append(`photo${idx + 1}`, photo.blob, photo.name)
                    }
                })
                return payload
            } catch (e) {
                console.log(e)
            }
        },
        preparedPayloadReq() {
            try {
                const payload = new FormData()

                payload.append('user_id', this.$store.getters.me.id)
                this.photos.forEach((photo, idx) => {
                    if (photo.blob) {
                        payload.append(`photo${idx + 1}`, photo.blob, photo.name)
                    }
                })
                payload.append('address', this.inputContent)

                return payload
            } catch (e) {
                console.log(e)
            }
        },
        onInputPhoto(e) {
            this.photos = e
        },
        onInputComment(value) {
            this.inputContent = value
        },
        checkWordCount() {
            if (!this.inputContent || this.inputContent.trim().length < 5) {
                this.$modal.basic({
                    body: `주소를 입력해주세요.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return true
            }
            return false
        },
    },
}
</script>
<style scoped lang="scss">
.page-modal {
    padding: 0 !important;
    @include modal-fullscreen;
    overflow: auto;

    .back-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // position:absolute;
        // top:0;
        position: sticky;
        top: 0;
        z-index: 3;
        background-color: white;
        .header-title {
            font-size: 18px;
            line-height: 28px;
        }
        .back-button {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin: 14px 8px 14px 16px;
        }
    }
    .main-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #111111;
        margin-left: 15px;
    }
    ::v-deep .section {
        padding: 0 16px;
        margin-bottom: 48px;
        .item-title {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #111111;
            margin: 30px 0 0 0;
            display: flex;
            justify-content: space-between;
        }
        .item-subtitle {
            font-weight: bold;
            font-size: 14px;
            line-height: 26px;
            color: #111111;
            margin-bottom: 6px;
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
        }
        .item-description {
            .method-title {
                font-weight: bold;
                font-size: 14px;
                line-height: 24px;
                color: #393d44;
                margin: 2px 0px;
            }
        }
        .item-list {
            display: flex;
            align-items: center;
            margin: 11px 0;
            & div {
                font-size: 14px;
                line-height: 24px;
            }
            .badge {
                font-weight: 500;
                font-size: 10px;
                line-height: 18px;
                text-align: center;
                color: #ffffff;
                margin-right: 10px;
                background: #3da1ff;
                border-radius: 100px;
            }
            .doc-name {
                color: #111;
            }
        }
        .hr {
            margin: 40px 0;
        }
    }

    .certificate-tabs {
        display: flex;
        border-bottom: solid 1px $grey-02;

        position: sticky;
        top: 0;
        background-color: white;

        .tab {
            position: relative;
            width: calc(100% / 2);
            height: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .label {
            font-size: 16px;
            color: $grey-06;

            &.active {
                color: $grey-09;

                @include f-medium;
            }
        }

        .indicator {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $grey-09;
            bottom: 0;
        }
    }
}
</style>
