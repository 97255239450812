<template>
    <div class="verification-badges-create">
        <section class="section">
            <template v-if="selectedType === 'photo'">
                <div>
                    <h2 class="section-title">인증 예시</h2>
                    <div v-for="(photo, idx) in images" :key="idx">
                        <!--                        <div class="example-title">{{ photo.title }}</div>-->
                        <div
                            class="example-image-wrapper"
                            :class="{ small: badge.id === 15 && certificateTab === 'BUSINESS_CARD' }"
                        >
                            <img v-if="imgSrc" :src="imgSrc" class="custom-img-size" />
                            <img v-else class="example-image" :src="photo" />
                        </div>
                    </div>
                    <ul class="attach-photos">
                        <li
                            v-for="(photo, idx) in photos"
                            :key="photo.url"
                            class="attach-photo"
                            @click="onClickPhoto(idx)"
                            :class="{ mandatory: idx === 0 }"
                        >
                            <img v-if="photo.url" :src="photo.url" class="photo" />
                            <img
                                v-else-if="idx === 0"
                                :src="require(`@/assets/images/icons/plus_blue.png`)"
                                width="24px"
                                height="24px"
                            />
                            <img v-else :src="require(`@/assets/images/icons/plus.png`)" width="24px" height="24px" />
                        </li>
                    </ul>
                    <div class="notice">* 모든 인증자료는 확인 용도로만 사용합니다.</div>
                </div>
            </template>
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                @change="onChangeImage($event)"
                accept="image/*"
            />
        </section>
    </div>
</template>

<script>
export default {
    name: 'AddPhoto',
    props: {
        badge: {
            type: Object,
            required: false,
        },
        certificateTab: {
            type: String,
            required: false,
        },
        imgSrc: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        showTitle: false,
        photos: [],
        selectedIndex: '',
        selectedType: 'photo',
        email: '',
    }),
    mounted() {},
    computed: {
        images() {
            if (this.badge.id === 15 && this.certificateTab === 'BUSINESS_CARD') {
                return ['https://photo.vanillabridge.com/app_photos/businessCard.png']
            } else {
                return this.badge.example_photo_url.split('|')
            }
        },
    },

    methods: {
        onClickPhoto(index) {
            this.selectedIndex = index
            this.$refs.imageUploader.click()
        },
        onChangeImage(event) {
            if (!event || event.target.files.length === 0) return
            const selectedPhoto = this.photos[this.selectedIndex]
            selectedPhoto.name = event.target.files[0].name
            const file = event.target.files[0]
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                        // allowFreeAspectRatio: true,
                        params: {
                            viewMode: 1,
                            dragMode: 'move',
                            cropBoxMovable: false,
                            autoCropArea: 1,
                            toggleDragModeOnDblclick: false,
                            background: false,
                            guides: false,
                            center: false,
                            minContainerWidth: window.innerWidth,
                        },
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.photos[this.selectedIndex].url = URL.createObjectURL(croppedFile)
                    this.photos[this.selectedIndex].blob = croppedFile
                    this.selectedIndex = null

                    this.emit()
                })
        },
        emit() {
            this.$emit('input', this.photos)
            this.$emit('onChangeDeletePhotoIds', this.deletePhotoIds)
            this.photoChanged = true
        },
    },
    created() {
        this.photos = Array(3)
            .fill({})
            .map(p => {
                return { blob: null, name: null, url: null }
            })
    },
}
</script>

<style lang="scss" scoped>
.verification-badges-create {
    .section {
        display: flex;
        flex-direction: column;
        margin: 0;

        &-title {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #111111;
            margin: 8px 0;
        }
        .example-title {
            font-size: 14px;
            line-height: 24px;
            margin: 4px 0px;
        }
    }

    .attach-photos {
        $gap: 11px;
        $photo-size: calc((100vw - #{$gap * 2} - 32px) / 3);
        display: grid;
        grid-template-columns: repeat(3, $photo-size);
        column-gap: 11px;

        .attach-photo {
            position: relative;
            width: $photo-size;
            height: $photo-size;
            border-radius: 12px;
            border: solid 1px $grey-03;
            .material-icons {
                font-size: 24px;
                color: $grey-06;
                position: relative;
            }

            .photo {
                width: 100%;
                height: 100%;
                border-radius: 12px;
            }

            @include center;
        }
        .mandatory {
            border: 1px dashed #3da1ff;
            background: #f0f8ff;

            &::after {
                position: absolute;
                left: 4px;
                top: 4px;
                content: '필수';
                background-color: #3da1ff;
                border-radius: 8px;
                color: #ffffff;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                padding: 2px 4px;
            }
            ::v-deep .material-icons {
                color: #3da1ff;
            }
        }
    }

    .example-image-wrapper {
        min-height: 200px;
        width: 100%;
        height: 272px;
        background: $grey-03;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
        margin: 0 0 16px;
        &.small {
            height: 200px;
        }

        .example-image {
            padding: 12px 0;
            width: 200px;
            height: 100%;
            object-fit: contain;
        }
    }
    .notice {
        color: #3da1ff;
        font-size: 12px;
        line-height: 20px;
        margin-top: 7px;
    }

    .custom-img-size {
        padding: 12px 0;
        width: 200px;
        height: 100%;
        object-fit: contain;
    }
}
</style>
